<template>
  <div class="user-invoice-section">
    <headerCustom></headerCustom>
    <div class="profile-section main-content">
      <div class="heading-sec">
        <div class="row">
          <div class="col-md-4 column">
            <div class="heading-profile">
              <h3>HOTEL</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- title section end -->
      <div class="message-section mt-5">
        <b-container>
          <b-row class="justify-content-center">
            <b-col>
              <div class="my-5 mx-auto">
                <b-form-group>
                  <div>
                    <label for="">Message:</label>
                  </div>

                  <textarea name="" id="" cols="90" rows="6" placeholder="Enter Message"></textarea>
                </b-form-group>
                <div class="d-flex">
                  <div>
                    <b-button class="btn-success">Send</b-button>
                  </div>
                  <div class="ml-3">
                    <b-button class="btn-danger">Cancel</b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import img1 from "@/assets/images/logo.png";
import headerCustom from "@/components/Header.vue";

export default {
  components: {
    headerCustom
  },
  data() {}
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.user-invoice-section {
  .profile-section {
    .message-section {
      background: $white;
    }
  }
}
</style>
